/* Fonts */

@font-face {
    font-family: 'Geist';
    src: url('../assets/fonts/Geist[wght].woff2') format('woff2-variations');
    font-weight: 100 900;
    font-style: normal;
}

/* @font-face {
    font-family: 'Geist Mono';
    src: url('../assets/fonts/GeistMono[wght].woff2') format('woff2-variations');
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-VariableFont_wght.woff2') format('woff2-variations');
    font-weight: 100 900;
    font-style: normal;
} */

/* Media Queries */

@media (max-width: 639px) { /* xs */

}

@media (min-width: 640px) { /* s */

}

@media (min-width: 768px) { /* md */

}

@media (min-width: 1145px) { /* lg */

}

@media (min-width: 1280px) { /* xl */

}

@media (min-width: 1536px) { /* 2xl */

}

button {
    font-family: 'Geist';
}

:root {
    --bg: #ffffff;
    --black: #121212;
    --black-muted: #1F2937;
    --black-muted-softer: #4B5563;
    --image-bg: #e1e1e1;
    --gray-p: #71717a;
    --gray-blue-nav: #717a7a;
    --accent-color: #2962ff; 
    --base-color: #e3f2fd; 
    --border-color-stays: #bbdefb;
    --border-color: #bbdefb00;
}

[data-color-theme='purple'] {
    --accent-color: #9c27b0;
    --base-color: #f3e5f5;
    --border-color-stays: #e1bee7;
    --border-color: #e1bee700;
}

[data-color-theme='green'] {
    --accent-color: #2e7d32;
    --base-color: #e8f5e9;
    --border-color-stays: #c8e6c9;
    --border-color: #c8e6c900;
}

[data-color-theme='orange'] {
    --accent-color: #f57c00; 
    --base-color: #fff3e0;
    --border-color-stays: #ffe0b2;
    --border-color: #ffe0b200;
}

[data-color-theme='red'] {
    --accent-color: #d50000; 
    --base-color: #ffebee;
    --border-color-stays: #ffcdd2;
    --border-color: #ffcdd200;
    
}

[data-theme='dark'] {
    --bg: #121212; 
    --black: #FFFFFF; 
    --black-muted: #E3EEFF;
    --black-muted-softer: #ADB8C9;
    --gray-p: #98989e;
    --gray-blue-nav: #889191;
    --image-bg: #E3EEFF;

    --accent-color: #426ad7;
    --base-color: #1e3048; 
    --border-color-stays: #3c4953;
    --border-color: #3c4953;
}

[data-color-theme='purple'][data-theme='dark'] {
    --accent-color: #9f68f8;
    --base-color: #322834;
    --border-color-stays: #3D333F;
    --border-color: #3D333F;
}

[data-color-theme='green'][data-theme='dark'] {
    --accent-color: #2E7C32;
    --base-color: #1E271F;
    --border-color--stays: #373E36;
    --border-color: #373E36;
}

[data-color-theme='orange'][data-theme='dark'] {
    --accent-color: #B34501;
    --base-color: #574C3C;
    --border-color-stays: #373E36;
    --border-color: #373E36;
}

[data-color-theme='red'][data-theme='dark'] {
    --accent-color: #ab281f;
    --base-color: #302225;
    --border-color--stays: #574547;
    --border-color: #574547;
}

/* @media (prefers-color-scheme: dark) {
    :root {
        --bg: #121212; 
        --black: #FFFFFF; 
        --black-muted: #E3EEFF;
        --black-muted-softer: #ADB8C9;
        --gray-p: #98989e;
        --gray-blue-nav: #889191;
        --image-bg: #E3EEFF;
        
        --accent-color: #426ad7;
        --base-color: #1e3048; 
        --border-color-stays: #3c4953;
        --border-color: #3c4953;
        --accent-tag: #bfdbfe;
    }
} */